<template>
  <div class="base-content" :class="{ 'base-content--no-padding': noPadding }">
    <div class="base-content__wrapper">
      <slot />
    </div>
    <BaseFooter v-if="showFooter" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseFooter from '@/components/base/BaseFooter.vue';

export default defineComponent({
  name: 'BaseContent',
  components: { BaseFooter },
  props: {
    noPadding: Boolean,
    showFooter: { type: Boolean, default: true },
  },
});
</script>

<style lang="scss" scoped>
.base-content {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &--no-padding {
    padding: 0;
  }

  &__wrapper {
    flex: 1;
    padding: var(--base-content-padding);
    box-sizing: border-box;
  }
}
</style>
