
import { defineComponent } from 'vue';
import BaseFooter from '@/components/base/BaseFooter.vue';

export default defineComponent({
  name: 'BaseContent',
  components: { BaseFooter },
  props: {
    noPadding: Boolean,
    showFooter: { type: Boolean, default: true },
  },
});
