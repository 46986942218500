
import { defineComponent } from 'vue';
import { IonFooter, IonButton, IonIcon } from '@ionic/vue';
import { RouteName } from '@/router/route-const';
import { logoTwitter } from 'ionicons/icons';

export default defineComponent({
  name: 'BaseFooter',
  components: { IonFooter, IonButton, IonIcon },
  setup() {
    return { RouteName, logoTwitter };
  },
});
