<template>
  <ion-footer class="base-footer">
    <div class="base-footer__links">
      <ion-button
        color="primary"
        fill="clear"
        size="small"
        @click="$router.push({ name: RouteName.DISCLAIMER })"
        v-text="$t('views.disclaimer')"
      />
      <ion-button
        color="primary"
        fill="clear"
        size="small"
        @click="$router.push({ name: RouteName.PRIVACY_POLICY })"
        v-text="$t('views.privacy-policy')"
      />
      <ion-button
        color="primary"
        fill="clear"
        size="small"
        @click="$router.push({ name: RouteName.TERMS_AND_CONDITIONS })"
        v-text="$t('views.terms-and-conditions')"
      />
    </div>
    <div class="base-footer__links">
      <ion-button
        size="small"
        color="twitter"
        fill="clear"
        target="_blank"
        href="https://twitter.com/TokenVoteCo"
      >
        <ion-icon slot="start" :icon="logoTwitter" />
        Twitter
      </ion-button>
      <ion-button
        size="small"
        color="telegram"
        fill="clear"
        target="_blank"
        href="https://t.me/TokenVoteCo"
      >
        <ion-icon
          slot="start"
          :icon="require('@/assets/images/ic_telegram.svg')"
        />
        Telegram
      </ion-button>
    </div>
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonFooter, IonButton, IonIcon } from '@ionic/vue';
import { RouteName } from '@/router/route-const';
import { logoTwitter } from 'ionicons/icons';

export default defineComponent({
  name: 'BaseFooter',
  components: { IonFooter, IonButton, IonIcon },
  setup() {
    return { RouteName, logoTwitter };
  },
});
</script>

<style lang="scss" scoped>
.base-footer {
  padding: var(--base-content-padding);
  background-color: var(--ion-color-secondary);
  box-shadow: var(--top-only-box-shadow);

  &__links {
    display: grid;
    grid-auto-flow: column;
  }
}
</style>
