<template>
  <ion-toolbar class="base-toolbar" color="dark" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button
        v-if="hasBackButton"
        :default-href="'/home'"
        :text="$t('global.back')"
      />
      <ion-menu-button v-else color="light" />
    </ion-buttons>
    <ion-title v-if="!showAppName" v-text="pageTitle" />
    <ion-title v-else>
      <span class="base-toolbar__title-gold">Token</span>
      <span class="base-toolbar__title-white">Vote.co</span>
    </ion-title>
    <ion-buttons slot="end">
      <img
        class="base-toolbar__logo"
        alt="TokenVote"
        :src="require('@/assets/images/logo_tokenvote.jpeg')"
      />
    </ion-buttons>
  </ion-toolbar>
</template>

<script lang="ts">
import {
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonMenuButton,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseToolbar',
  props: {
    showAppName: Boolean,
    pageTitle: String,
    hasBackButton: Boolean,
  },
  components: {
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonMenuButton,
  },
});
</script>

<style lang="scss" scoped>
.base-toolbar {
  border-bottom: 2px solid var(--ion-color-primary);

  &__logo {
    max-height: 2.5em;
  }

  &__title-gold,
  &__title-white {
    font-weight: var(--fw-bold);
  }

  &__title-gold {
    color: var(--ion-color-primary);
  }
}
</style>
