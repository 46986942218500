
import {
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonMenuButton,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseToolbar',
  props: {
    showAppName: Boolean,
    pageTitle: String,
    hasBackButton: Boolean,
  },
  components: {
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonMenuButton,
  },
});
