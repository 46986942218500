<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.not-found')" />
    </ion-header>
    <ion-content>
      <BaseContent class="not-found">
        {{ $t('not-found.message') }}

        <ion-button
          color="primary"
          fill="clear"
          expand="block"
          @click="$router.push('/home')"
          v-text="$t('not-found.back')"
        />
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import { IonPage, IonContent, IonHeader, IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'NotFound',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
});
</script>

<style lang="scss" scoped>
.not-found {
}
</style>
